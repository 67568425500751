import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.AgencyState || initialState;

export const agenciesListSelector = createSelector(selectDomain, (state) => state.agenciesList);
export const agenciesListLoadingSelector = createSelector(selectDomain, (state) => state.isLoading);
export const pageSelector = createSelector(selectDomain, (state) => state.page);
export const isDeleteModalOpenSelector = createSelector(selectDomain, (state) => state.isDeleteModalOpen);
export const selectedAgencySelector = createSelector(selectDomain, (state) => state.selectedAgency);
export const pageSizeSelector = createSelector(selectDomain, (state) => state.pageSize);
export const totalSelector = createSelector(selectDomain, (state) => state.total);
export const sortBySelector = createSelector(selectDomain, (state) => state.sortBy);
export const propertiesListByAgencySelector = createSelector(selectDomain, (state) => state.propertiesList);
export const agentsListByAgencySelector = createSelector(selectDomain, (state) => state.agentsList);
export const agencyFormIsLoadingSelector = createSelector(selectDomain, (state) => state.agencyFormIsLoading);
export const agencySettingTabSelector = createSelector(selectDomain, (state) => state.agencySettingTab);
export const financialsListSelector = createSelector(selectDomain, (state) => state.financialsList);
export const finPageSelector = createSelector(selectDomain, (state) => state.finPage);
export const finPageSizeSelector = createSelector(selectDomain, (state) => state.finPageSize);
export const finTotalSelector = createSelector(selectDomain, (state) => state.finTotal);
export const finSortBySelector = createSelector(selectDomain, (state) => state.finSortBy);
export const finSortOrderSelector = createSelector(selectDomain, (state) => state.finSortOrder);
export const selectedFinancialPropertySelector = createSelector(selectDomain, (state) => state.selectedFinancialProperty);
export const financialsListLoadingSelector = createSelector(selectDomain, (state) => state.financialsListLoading);
export const sortOrderSelector = createSelector(selectDomain, (state) => state.sortOrder);
export const searchSelector = createSelector(selectDomain, (state) => state.search);
