import { Column, ColumnAgency } from './interface';
import { Button, ButtonProps } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/system';
import { TabsProps } from 'types';
import colors from 'assets/scss/_themes-vars.module.scss';

import {
    DocumentsIconLight,
    CalendarToday,
    FinancialsIconLight,
    PropertyCardsIcon,
    PropertyListIcon,
    PropertyMapsIcon,
    UnitsIcon
} from 'ui-component/Icons';
import Chip from 'ui-component/extended/Chip';

import routeConstant from 'routes/routeConstant';
import { mockImageHelper } from 'utils/helper';

export const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: 'white',
    backgroundColor: theme.palette.primary.main
}));

export const TabPanel = ({ children, value, index, ...other }: TabsProps) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
};

type TabOptionsType = {
    [key: string]: number;
};

export const columns: readonly Column[] = [
    {
        id: 'clientName',
        label: 'Name',
        fontWeight: 600
    },
    { id: 'property', label: 'Property' },
    {
        id: 'phone',
        label: 'Phone'
    },
    {
        id: 'agent_name',
        label: 'Agent'
    },
    {
        id: 'agency_name',
        label: 'Agency'
    },
    {
        id: 'client_status',
        label: 'Client Status'
    },
    {
        id: 'paymentStatus',
        label: 'Payment Status'
    },
    {
        id: 'action',
        label: 'Action'
    }
];

export const columnsAgency: readonly ColumnAgency[] = [
    {
        id: 'clientName',
        label: 'Name',
        fontWeight: 600
    },
    { id: 'property', label: 'Property' },
    {
        id: 'unit',
        label: 'Units'
    },
    {
        id: 'agent',
        label: 'Agent'
    },
    {
        id: 'accountManager',
        label: 'Account Manager'
    },
    {
        id: 'phone',
        label: 'Phone'
    },
    {
        id: 'email',
        label: 'Email'
    },
    {
        id: 'nationality',
        label: 'Nationality'
    },
    {
        id: 'paymentStatus',
        label: 'Payment Status'
    },
    {
        id: 'action',
        label: 'Action'
    }
];

export const tabsOption = [
    {
        label: 'Cards',
        icon: <PropertyCardsIcon />
    },
    {
        label: 'List',
        icon: <PropertyListIcon />
    },
    {
        label: 'Map',
        icon: <PropertyMapsIcon />
    }
];

// Data -
export const clientsList = [
    {
        clientsImg: mockImageHelper('1FkXuC8TBpIerySZ_EDOS75B-XYFiv1yc'),
        name: 'Adnam Amin',
        profession: 'Property Owner',
        clientInfo:
            'Our client, a discerning homebuyer, demonstrated clear vision and trust in our expertise, making the process of finding their ideal residential property both efficient and rewarding.',
        email: 'sonjianm@gmail.com',
        state: 'Hong Kong',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Aisam Ul Haq Qureshi',
        profession: 'Property Owner',
        clientInfo:
            'Our client, a discerning homebuyer, demonstrated clear vision and trust in our expertise, making the process of finding their ideal residential property both efficient and rewarding.',
        email: 'sonjianm@gmail.com',
        state: 'Hong Kong',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Ala Eddin Al Jindi',
        profession: 'Property Owner',
        clientInfo: 'Information about client...',
        email: 'sonjianm@gmail.com',
        state: 'Hong Kong',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Alain & Isabel Bonte',
        profession: 'Property Owner',
        clientInfo: 'Information about client...',
        email: 'sonjianm@gmail.com',
        state: 'Hong Kong',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Alain Low & Marie-Laetitia Zichy',
        profession: 'Property Owner',
        clientInfo: 'Information about client...',
        email: 'sonjianm@gmail.com',
        state: 'Hong Kong',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Alexander Levin',
        profession: 'Property Owner',
        clientInfo: 'Information about client...',
        email: 'sonjianm@gmail.com',
        state: 'Hong Kong',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Alexandra Roth',
        profession: 'Property Owner',
        clientInfo: 'Information about client...',
        email: 'sonjianm@gmail.com',
        state: 'Brazil',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Alistair Walters',
        profession: 'Property Owner',
        clientInfo: 'Information about client...',
        email: 'sonjianm@gmail.com',
        state: 'Cayman Islands',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Allan Walker',
        profession: 'Property Owner',
        clientInfo: 'Information about client...',
        email: 'sonjianm@gmail.com',
        state: 'Ireland',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Aly Janmohamed',
        profession: 'Property Owner',
        clientInfo: 'Information about client...',
        email: 'sonjianm@gmail.com',
        state: 'United Kingdom',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Amna Humayn Shaikh',
        profession: 'Property Owner',
        clientInfo: 'Information about client...',
        email: 'sonjianm@gmail.com',
        state: 'Pakistan',
        phone: '192-223-4256',
        city: 'Santa Clara'
    },
    {
        clientsImg: null,
        name: 'Amna Humayn Shaikh',
        profession: 'Property Owner',
        clientInfo: 'Information about client...',
        email: 'sonjianm@gmail.com',
        state: 'Pakistan',
        phone: '192-223-4256',
        city: 'Santa Clara'
    }
];

export const clientsMap = [
    {
        latitude: 24.9668,
        longitude: 45.9432
    },
    {
        latitude: 30.8025,
        longitude: 26.8206
    },
    {
        latitude: 20.593683,
        longitude: 78.962883
    },
    {
        latitude: 48.019573,
        longitude: 66.923683
    },
    {
        latitude: 55.755825,
        longitude: 37.617298
    }
];

export const clientList = [
    {
        clientImg: mockImageHelper('1JcFK6aUIwOpbSH0A3nbc49izlXpsXHB1'),
        clientName: 'Adnam Amin',
        property: 'Jardim da Glória',
        unit: 'GS-1B',
        accountManager: 'Elina Costa Duarte',
        phone: '+3151552525',
        email: 'adnam@gmail.com',
        nationality: 'Kenya',
        paymentStatus: 65
    },
    {
        clientImg: null,
        clientName: 'Santa Isabel',
        property: 'Jardim da Glória',
        unit: 'GS-1B',
        accountManager: 'Elina Costa Duarte',
        phone: '+3151552525',
        email: 'adnam@gmail.com',
        nationality: 'Kenya',
        paymentStatus: 65
    },
    {
        clientImg: null,
        clientName: 'Trigo',
        property: 'Jardim da Glória',
        unit: 'GS-1B',
        accountManager: 'Elina Costa Duarte',
        phone: '+3151552525',
        email: 'adnam@gmail.com',
        nationality: 'Kenya',
        paymentStatus: 65
    },
    {
        clientImg: null,
        clientName: 'Rossio 85',
        property: 'Jardim da Glória',
        unit: 'GS-1B',
        accountManager: 'Elina Costa Duarte',
        phone: '+3151552525',
        email: 'adnam@gmail.com',
        nationality: 'Kenya',
        paymentStatus: 65
    },
    {
        clientImg: null,
        clientName: 'Villa Maria Pia',
        property: 'Jardim da Glória',
        unit: 'GS-1B',
        accountManager: 'Elina Costa Duarte',
        phone: '+3151552525',
        email: 'adnam@gmail.com',
        nationality: 'Kenya',
        paymentStatus: 65
    },
    {
        clientImg: null,
        clientName: 'Rossio 93',
        property: 'Jardim da Glória',
        unit: 'GS-1B',
        accountManager: 'Elina Costa Duarte',
        phone: '+3151552525',
        email: 'adnam@gmail.com',
        nationality: 'Kenya',
        paymentStatus: 65
    }
];

export const tabOptions = (props: any) => [
    {
        to: routeConstant.clientsProfile,

        label: (
            <div className="tabs-container">
                <PropertyCardsIcon />
                <div className="tabs-container-text">Profile</div>
            </div>
        )
    },
    {
        to: routeConstant.clientsProfile,

        label: (
            <div className="tabs-container">
                {UnitsIcon}
                <div className="tabs-container-text">
                    Units
                    <Chip
                        label={props?.units}
                        size="small"
                        sx={{ ml: 1.5, backgroundColor: colors.primaryLight, color: colors.primaryMain }}
                    />
                </div>
            </div>
        )
    },
    {
        to: routeConstant.clientsProfile,

        label: (
            <div className="tabs-container">
                <CalendarToday height="17" width="17" />
                <div className="tabs-container-text">
                    Updates
                    <Chip
                        label={props?.updates}
                        size="small"
                        sx={{ ml: 1.5, backgroundColor: colors.primaryLight, color: colors.primaryMain }}
                    />
                </div>
            </div>
        )
    },
    {
        to: routeConstant.clientsProfile,

        label: (
            <div className="tabs-container">
                {DocumentsIconLight}
                <div className="tabs-container-text">
                    Documents
                    <Chip
                        label={props?.documents}
                        size="small"
                        sx={{ ml: 1.5, backgroundColor: colors.primaryLight, color: colors.primaryMain }}
                    />
                </div>
            </div>
        )
    },
    {
        to: routeConstant.clientsProfile,
        label: (
            <div className="tabs-container">
                {FinancialsIconLight}
                <div className="tabs-container-text">Financials</div>
            </div>
        )
    }
];

export const agencyTabOptions = (props: TabOptionsType) => {
    return [
        {
            to: routeConstant.propertiesProfile,
            label: (
                <div className="tabs-container">
                    <PropertyCardsIcon />
                    <div className="tabs-container-text">Profile</div>
                </div>
            )
        },
        {
            label: (
                <div className="tabs-container">
                    {UnitsIcon}
                    <div className="tabs-container-text">
                        Properties
                        <Chip
                            label={props.properties}
                            size="small"
                            sx={{ ml: 1.5, backgroundColor: colors.primaryLight, color: colors.primaryMain }}
                        />
                    </div>
                </div>
            )
        },
        {
            label: (
                <div className="tabs-container">
                    <CalendarToday height="21" width="21" />
                    <div className="tabs-container-text">
                        Updates
                        <Chip
                            label={props.updates}
                            size="small"
                            sx={{ ml: 1.5, backgroundColor: colors.primaryLight, color: colors.primaryMain }}
                        />
                    </div>
                </div>
            )
        },
        {
            label: (
                <div className="tabs-container">
                    {DocumentsIconLight}
                    <div className="tabs-container-text">
                        Documents
                        <Chip
                            label={props.documents}
                            size="small"
                            sx={{ ml: 1.5, backgroundColor: colors.primaryLight, color: colors.primaryMain }}
                        />
                    </div>
                </div>
            )
        },
        {
            to: routeConstant.clientsProfile,
            label: (
                <div className="tabs-container">
                    {FinancialsIconLight}
                    <div className="tabs-container-text">Financials</div>
                </div>
            )
        }
    ];
};

export const agentsTabOptions = [
    {
        to: routeConstant.clientsProfile,

        label: (
            <div className="tabs-container">
                <PropertyCardsIcon />
                <div className="tabs-container-text">Profile</div>
            </div>
        )
    },
    {
        to: routeConstant.clientsProfile,

        label: (
            <div className="tabs-container">
                {UnitsIcon}
                <div className="tabs-container-text">Units</div>
            </div>
        )
    },
    {
        to: routeConstant.clientsProfile,

        label: (
            <div className="tabs-container">
                <CalendarToday height="17" width="17" />
                <div className="tabs-container-text">
                    Updates <Chip label="01" size="small" chipcolor="secondary" sx={{ ml: 1.5 }} />
                </div>
            </div>
        )
    },
    {
        to: routeConstant.clientsProfile,

        label: (
            <div className="tabs-container">
                {DocumentsIconLight}
                <div className="tabs-container-text">
                    Documents <Chip label="01" size="small" chipcolor="secondary" sx={{ ml: 1.5 }} />
                </div>
            </div>
        )
    },
    {
        to: routeConstant.clientsProfile,
        label: (
            <div className="tabs-container">
                {FinancialsIconLight}
                <div className="tabs-container-text">Financials</div>
            </div>
        )
    }
];
