import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.usersState || initialState;

export const managersListSelector = createSelector(selectDomain, (state) => state.managersList);
export const clientsListSelector = createSelector(selectDomain, (state) => state.clientsList);
export const isForgotPasswordLoadingSelector = createSelector(selectDomain, (state) => state.isForgotPasswordLoading);
export const isLoadingSelector = createSelector(selectDomain, (state) => state.isLoading);
export const userDetailsSelector = createSelector(selectDomain, (state) => state.userDetails);
export const userSettingTabSelector = createSelector(selectDomain, (state) => state.userSettingTab);
