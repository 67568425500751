import { createSlice } from '@reduxjs/toolkit';
import { documentDetailsType, documentType } from './types';

export const initialState: documentDetailsType = {
    documentsList: [],
    isLoading: false,
    selectedDocument: {} as documentType,
    propertiesList: [],
    isDeleteModalOpen: false,
    documentFormIsLoading: false,
    unitsList: [],
    page: 1,
    pageSize: 12,
    total: 0,
    sortBy: 'createdAt',
    search: '',
    sortOrder: 'desc',
};

const document = createSlice({
    name: 'DocumentState',
    initialState,
    reducers: {
        setDocumentsList(state, action) {
            state.documentsList = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setSelectedDocument(state, action) {
            state.selectedDocument = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setTotal(state, action) {
          state.total = action.payload;
        },
        setSortBy(state, action) {
            state.sortBy = action.payload;
        },
        setPropertiesList(state, action) {
            state.propertiesList = action.payload;
        },
        setUnitsList(state, action) {
            state.unitsList = action.payload;
        },
        setToggleDeleteModal(state, action) {
            state.isDeleteModalOpen = action.payload;
        },
        setSearch(state, action) {
            state.search = action.payload;
        },
        setDocumentFormIsLoading(state, action) {
            state.documentFormIsLoading = action.payload;
        },
        setSortOrder(state, action) {
            state.sortOrder = action.payload;
        }
    }
});

export const { actions: DocumentsReducerAction, name: DocumentsReducerName, reducer: DocumentsReducer } = document;
