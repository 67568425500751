const routeConstant = {
    blank: '/',
    initial: '/home',
    // Auth Route Start
    login: '/login',
    register: '/register',
    forgotPassword: '/forgot-password',
    resetPassword: '/user/reset-password',
    checkMail: '/check-mail',
    resetPasswordLinkSent: '/reset-password-link-sent',
    // Auth Route End
    accessDenied: '/access-denied',
    dashboard: '/dashboard',
    userSettings: '/apps/user/settings',
    updates: '/updates',
    updatesProfile: '/updates/profile',
    updatesForms: '/updates/add',
    updatesEdit: '/updates/edit',
    clients: '/clients',
    clientsProfile: '/clients/profile',
    clientsForms: '/client/add',
    clientsEdit: '/client/edit',
    properties: '/properties',
    propertiesProfile: '/properties/profile',
    propertiesForms: '/property/add',
    propertiesEdit: '/property/edit',
    unitAdd: '/unit/add',
    unitEdit: '/unit/edit',
    documents: '/documents',
    newDocument: '/document/add',
    editDocument: '/document/edit',
    financials: '/financials',
    agents: '/agents',
    agentsProfile: '/agents/profile',
    agentsAdd: '/agents/add',
    agentsEdit: '/agents/edit',
    agencies: '/agencies',
    agenciesProfile: '/agencies/profile',
    agenciesAdd: '/agencies/add',
    agenciesEdit:  '/agencies/edit',
    provider: '/suppliers',
    providerProfile: '/suppliers/profile',
    providerAdd: '/suppliers/add',
    providerEdit: '/suppliers/edit',
    managers: '/managers',
    managersProfile: '/managers/profile',
    managerAdd: '/managers/add',
    managerEdit: '/managers/edit',
    globalSearch: '/search',
    any: '*'
};

export default routeConstant;
