import { Grid } from '@mui/material';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export const formatNumber = (num: number | string) => {
    return num?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

// Function to handle the reverse format from 'de-DE' format to number
export const reverseFormatNumber = (formattedNum: string): number | '' => {
    if (!formattedNum) return '';
    const cleanNumber = formattedNum?.replace(/\./g, '').replace(',', '.');
    return parseInt(cleanNumber);
};

export const formatPortugueseNumber = (value: any) => {
    if (!value) return '';
    const parts = value.split(',');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
};

export const parsePortugueseNumber = (value: any) => {
    if (!value) return '';
    const parsedNumber = value?.replace(/\./g, '')?.replace(',', '.');
    return parseFloat(parsedNumber);
};

export const dropDownHelper = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Main building
            </Grid>
        ),
        value: 0
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Golden Visa
            </Grid>
        ),
        value: 2
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Elina Costa
            </Grid>
        ),
        value: 3
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Portugal
            </Grid>
        ),
        value: 4
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Lebanese
            </Grid>
        ),
        value: 6
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />1 Parking
            </Grid>
        ),
        value: 7
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Storage
            </Grid>
        ),
        value: 8
    }
];

export const dropDownHelperInvestmentType = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Golden Visa
            </Grid>
        ),
        value: 'Golden Visa'
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Residential
            </Grid>
        ),
        value: 'Residential'
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Investment
            </Grid>
        ),
        value: 'Investment'
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Other
            </Grid>
        ),
        value: 'Other'
    }
];

export const dropDownHelperParkingSpots = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />1 Parking
            </Grid>
        ),
        value: 1
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />2 Parking
            </Grid>
        ),
        value: 2
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />3 Parking
            </Grid>
        ),
        value: 3
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />4 Parking
            </Grid>
        ),
        value: 4
    }
];

export const dropDownHelperStorage = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Yes
            </Grid>
        ),
        value: 1
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                No
            </Grid>
        ),
        value: 0
    }
];
export const dropDownHelperStatus = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Available
            </Grid>
        ),
        value: 1
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Sold
            </Grid>
        ),
        value: 0
    }
];

export const dropDownHelperFinancial = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Pending
            </Grid>
        ),
        value: 'Pending'
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Paid
            </Grid>
        ),
        value: 'Paid'
    }
];

export const dropDownHelperModifications = [
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Pending
            </Grid>
        ),
        value: 0
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Project Manager
            </Grid>
        ),
        value: 1
    },
    {
        label: (
            <Grid display="flex" gap="10px">
                <CheckCircleRoundedIcon fontSize="small" />
                Client Manager
            </Grid>
        ),
        value: 2
    }
];

export enum fieldType {
    textField = 'textField',
    selectField = 'selectField',
    dateTime = 'dateTime',
    button = 'button'
}
