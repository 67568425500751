import { NavItemType } from 'types';
import managerView, { agentView, clientView, agencyView, adminView } from './sidebarItems';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [adminView, managerView, agentView, clientView, agencyView]
};

export default menuItems;
