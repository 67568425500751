import { createSlice } from '@reduxjs/toolkit';
import { UserDetailsType, UsersType } from './types';

export const initialState: UsersType = {
    managersList: [],
    clientsList: [],
    userDetails: {} as UserDetailsType,
    isForgotPasswordLoading: false,
    userSettingTab: 0,
    isLoading: false,
};

const user = createSlice({
    name: 'usersState',
    initialState,
    reducers: {
        setManagersDetails(state, action) {
            state.managersList = action.payload;
        },
        setClientsDetails(state, action) {
            state.clientsList = action.payload;
        },
        setForgotPasswordLoading(state, action) {
            state.isForgotPasswordLoading = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setUserDetails(state, action) {
            state.userDetails = action.payload;
        },
        setUserSettingTab(state, action) {
            state.userSettingTab = action.payload;
        }
    }
});

export const { actions: UserReducerAction, name: UserReducerName, reducer: UserReducer } = user;
