import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.propertiesState || initialState;

export const PropertiesDetailsSelector = createSelector(selectDomain, (state) => state.propertyList);
export const isDeleteModalOpenSelector = createSelector(selectDomain, (state) => state.isDeleteModalOpen);
export const isDeleteUnitModalOpenSelector = createSelector(selectDomain, (state) => state.isDeleteUnitModalOpen);
export const selectedPropertySelector = createSelector(selectDomain, (state) => state.selectedProperty);
export const isLoadingSelector = createSelector(selectDomain, (state) => state.isLoading);
export const propertyUnitsListSelector = createSelector(selectDomain, (state) => state.propertyUnitsList);
export const propertyUpdatesListSelector = createSelector(selectDomain, (state) => state.propertyUpdatesList);
export const propertyDocumentsListSelector = createSelector(selectDomain, (state) => state.propertyDocumentsList);
export const propertyGalleryListSelector = createSelector(selectDomain, (state) => state.propertyGalleryList);
export const propertyDetailsSelector = createSelector(selectDomain, (state) => state.propertyDetails);
export const propertyDetailGalleryImagesSelector = createSelector(selectDomain, (state) => state.propertyDetailGalleryImages);
export const SelectedPropertyUnitSelector = createSelector(selectDomain, (state) => state.selectedPropertyUnit);
export const IsAddPropertyUnitLoading = createSelector(selectDomain, (state) => state.isAddPropertyUnitLoading);
export const UnitModificationsSelector = createSelector(selectDomain, (state) => state.unitsModificationsList);
export const SelectedUnitModificationSelector = createSelector(selectDomain, (state) => state.selectedUnitModification);
export const pageSelector = createSelector(selectDomain, (state) => state.page);
export const pageSizeSelector = createSelector(selectDomain, (state) => state.pageSize);
export const sortOrderSelector = createSelector(selectDomain, (state) => state.sortOrder);
export const totalSelector = createSelector(selectDomain, (state) => state.total);
export const sortBySelector = createSelector(selectDomain, (state) => state.sortBy);
export const searchSelector = createSelector(selectDomain, (state) => state.search);
export const unitsPageSelector = createSelector(selectDomain, (state) => state.unitsPage);
export const unitsPageSizeSelector = createSelector(selectDomain, (state) => state.unitsPageSize);
export const unitsSortOrderSelector = createSelector(selectDomain, (state) => state.unitsSortOrder);
export const UnitsTotalSelector = createSelector(selectDomain, (state) => state.unitsTotal);
export const PropertyUpdatesListLoading = createSelector(selectDomain, (state) => state.isPropertyUpdatesListLoading);
export const unitsSortBySelector = createSelector(selectDomain, (state) => state.unitsSortBy);
export const unitsSearchSelector = createSelector(selectDomain, (state) => state.unitsSearch);
export const propertyUnitsListLoadingSelector = createSelector(selectDomain, (state) => state.isPropertyunitsListLoading);
export const unitsFinancialsListSelector = createSelector(selectDomain, (state) => state.unitFinancialsList);
export const ClientsAutocompleteListSelector = createSelector(selectDomain, (state) => state.clientsList);
export const fiscalRepresentativesListSelector = createSelector(selectDomain, (state) => state.fiscalRepresentativesList);
export const LegalRepresentativesListSelector = createSelector(selectDomain, (state) => state.legalRepresentativesList);
export const TechniciansListSelector = createSelector(selectDomain, (state) => state.techniciansList);
export const ArchitectsListSelector = createSelector(selectDomain, (state) => state.architectsList);
