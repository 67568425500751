import { AxiosResponse } from 'axios';
import { DownloadFile, ForgotPassword, GetUserDetailsByToken, GetUsersByRole, ResetPassword, UpdateUserProfile, UserResetPassword } from 'services/user';
import { createSliceSaga } from 'store/saga-helper';
import { UserReducerAction } from './slice';
import { call, put } from 'redux-saga/effects';
import { SnackbarReducerAction } from 'store/snackbar/slice';

const saga = createSliceSaga({
    name: 'users-state',
    caseSagas: {
        *getUserByManagerRole() {
            try {
                const response: AxiosResponse = yield call(GetUsersByRole, { role: 'manager' });
                if (response?.status === 200) {
                    UserReducerAction.setManagersDetails([]);
                    yield put(UserReducerAction.setManagersDetails(response.data.data));
                }
            } catch (error) {
                console.log('Error in Fetch users by role API', error);
                throw new Error();
            }
        },
        *getUserByClientRole() {
            try {
                const response: AxiosResponse = yield call(GetUsersByRole, { role: 'client' });
                if (response?.status === 200) {
                    UserReducerAction.setClientsDetails([]);
                    yield put(UserReducerAction.setClientsDetails(response.data.data));
                }
            } catch (error) {
                console.log('Error in Fetch users by role API', error);
                throw new Error();
            }
        },
        *downloadFile(action: { payload: { [key: string]: any } }) {
            try {
                const response: { [key: string]: any } = yield call(DownloadFile, action.payload);
                const func = async () => {
                    if (response.ok) {
                        const blob = await response.blob();
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `${action.payload.fileName}.xlsx`; // You can set the file name here
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                };
                func();
            } catch (error) {
                console.log('Error in download data API', error);
                throw new Error();
            }
        },
        *forgotPassword(action: { payload: { [key: string]: any } }) {
            try {
                yield put(UserReducerAction.setForgotPasswordLoading(true));
                const { values, handleNavigate } = action.payload;
                const response: AxiosResponse = yield call(ForgotPassword, values);
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.data?.message,
                            close: false
                        })
                    );
                    handleNavigate();
                } else {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.message,
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Forgot Password API', error);
                throw new Error();
            } finally {
                yield put(UserReducerAction.setForgotPasswordLoading(false));
            }
        },
        *resetPassword(action: { payload: any }) {
            try {
                yield put(UserReducerAction.setForgotPasswordLoading(true));
                const { values, token, handleNavigate } = action.payload;
                const response: AxiosResponse = yield call(ResetPassword, { values, token });
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.message,
                            close: false
                        })
                    );
                    handleNavigate();
                } else {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Check mail for reset password link',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Forgot Password API', error);
                throw new Error();
            } finally {
                yield put(UserReducerAction.setForgotPasswordLoading(false));
            }
        },
        *updateUerProfile(action: { payload: { [key: string]: any } }) {
            try {
                yield put(UserReducerAction.setIsLoading(true));
                const { values, handleNavigate } = action.payload;
                const response: AxiosResponse = yield call(UpdateUserProfile, values);
                if (response?.status === 200) {
                    yield put(UserSagaActions.getUserDetailsByToken());
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.message,
                            close: false
                        })
                    );
                    handleNavigate();
                } else {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.message,
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Update User details API', error);
                throw new Error();
            } finally {
                yield put(UserReducerAction.setIsLoading(false));
            }
        },
        *getUserDetailsByToken() {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const response: AxiosResponse = yield call(GetUserDetailsByToken, {
                    accessToken: serviceToken
                });
                if (response?.status === 200) {
                    UserReducerAction.setUserDetails([]);
                    yield put(UserReducerAction.setUserDetails(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Fetch user details by role API', error);
                throw new Error();
            }
        },
        *userResetPassword(action: {payload: { [key: string]: any }}) {
            try {
                yield put(UserReducerAction.setIsLoading(true));
                const { values, handleNavigate } = action.payload;
                const response: AxiosResponse = yield call(UserResetPassword, values);
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.data?.message,
                            close: false
                        })
                    );
                    handleNavigate();
                } else {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.message,
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Forgot Password API', error);
                throw new Error();
            } finally {
                yield put(UserReducerAction.setIsLoading(false));
            }
        }
    },
    sagatype: 'takeEvery'
});

export const { actions: UserSagaActions, saga: UserSaga, name: UserSagaName } = saga;
