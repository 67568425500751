// material-ui
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import './style-loader.scss';
import { Backdrop, CircularProgress } from '@mui/material';

// styles
const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%'
});

// ==============================|| LOADER ||============================== //

const Loader = ({ backdropOpen }: { backdropOpen?: boolean }) => (
    <LoaderWrapper>
        <LinearProgress className="loader-linear-progress" />
        <Backdrop open={Boolean(backdropOpen)} className="loader-backdrop">
            <CircularProgress className="circular-loader" />
        </Backdrop>
    </LoaderWrapper>
);

export default Loader;
