import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';
import {
    AddDocument,
    DeleteDocumentById,
    GetAllDocuments,
    GetDocumentDetailsById,
    GetDocumentProperties,
    GetDocumentsByUserId,
    GetDocumentUnitsByPropertyId,
    UpdateDocumentById
} from 'services/documents';
import { createSliceSaga } from 'store/saga-helper';
import { ConvertToQueryString } from 'utils/common';
import { DocumentsReducerAction } from './slice';
import { SnackbarReducerAction } from 'store/snackbar/slice';

const saga = createSliceSaga({
    name: 'documents-state',
    caseSagas: {
        *fetchDocumentsListByUserId(action: { payload: { [key: string]: any } }) {
            try {
                const { id, page, pageSize, sortBy } = action.payload;
                yield put(DocumentsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetDocumentsByUserId, {
                    id,
                    query: ConvertToQueryString({ page, pageSize, sortBy })
                });

                if (response?.status === 200) {
                    DocumentsReducerAction.setDocumentsList([]);
                    DocumentsReducerAction.setTotal(0);
                    yield put(DocumentsReducerAction.setDocumentsList(response?.data));
                    yield put(DocumentsReducerAction.setTotal(response?.data?.totalCount));
                }
            } catch (error) {
                console.log('Error in Documents list by userId API', error);
            } finally {
                yield put(DocumentsReducerAction.setIsLoading(false));
            }
        },
        *fetchAllDocumentsList(action: { payload: { [key: string]: any } }) {
            try {
                yield put(DocumentsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(
                    GetAllDocuments,
                    ConvertToQueryString(action.payload)
                );
                if (response?.status === 200) {
                    yield put(DocumentsReducerAction.setDocumentsList([]));
                    yield put(DocumentsReducerAction.setTotal(0));
                    yield put(DocumentsReducerAction.setDocumentFormIsLoading(false));
                    yield put(DocumentsReducerAction.setDocumentsList(response?.data?.data));
                    yield put(DocumentsReducerAction.setTotal(response?.data?.totalCount));
                } else {
                    yield put(DocumentsReducerAction.setDocumentsList(response?.data ?? []));
                    yield put(DocumentsReducerAction.setTotal(0));
                }
            } catch (error) {
                console.log('Error in Documents list by userId API', error);
            } finally {
                yield put(DocumentsReducerAction.setIsLoading(false));
            }
        },
        *deleteDocumentById(action: { payload: string }) {
            try {
                yield put(DocumentsReducerAction.setDocumentFormIsLoading(true));
                const response: AxiosResponse = yield call(DeleteDocumentById, action.payload);
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Document Deleted Successfully'
                        })
                    );
                    yield put(DocumentsReducerAction.setDocumentsList([]));
                    yield put(DocumentsSagaAction.fetchAllDocumentsList({ page: 1, pageSize: 12 }));
                }
            } catch (error) {
                console.log('Error in Documents list by userId API', error);
            } finally {
                yield put(DocumentsReducerAction.setDocumentFormIsLoading(false));
            }
        },
        *addDocument(action: { payload: { values: { [key: string]: any }; handleNavigate: () => void } }) {
            try {
                const { values, handleNavigate } = action.payload;
                yield put(DocumentsReducerAction.setDocumentFormIsLoading(true));
                const response: AxiosResponse = yield call(AddDocument, values);
                if (response?.status === 200) {
                    handleNavigate();
                    DocumentsReducerAction.setSelectedDocument({});
                    yield put(DocumentsReducerAction.setDocumentsList([]));
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Document Added Successfully'
                        })
                    );
                } else {
                    yield put(
                        response?.data?.message &&
                            SnackbarReducerAction.openSnackbar({
                                open: true,
                                message: response?.data?.message
                            })
                    );
                }
            } catch (error) {
                console.log('Error in save Document API', error);
                throw new Error();
            } finally {
                yield put(DocumentsReducerAction.setDocumentFormIsLoading(false));
            }
        },
        *updateDocument(action: { payload: { values: { [key: string]: any }; handleNavigate: () => void } }) {
            try {
                const { values, handleNavigate } = action.payload;
                yield put(DocumentsReducerAction.setDocumentFormIsLoading(true));
                const response: AxiosResponse = yield call(UpdateDocumentById, values);
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Document details Updated Successfully'
                        })
                    );
                    handleNavigate();
                } else {
                    yield put(
                        response?.data?.message &&
                            SnackbarReducerAction.openSnackbar({
                                open: true,
                                message: response?.data?.message
                            })
                    );
                }
            } catch (error) {
                console.log('Error in Update Document API', error);
                throw new Error();
            } finally {
                yield put(DocumentsReducerAction.setDocumentFormIsLoading(false));
            }
        },
        *fetchDocumentProperties() {
            try {
                yield put(DocumentsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetDocumentProperties);

                if (response?.status === 200) {
                    DocumentsReducerAction.setPropertiesList([]);
                    yield put(DocumentsReducerAction.setPropertiesList(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Document Properties list API', error);
            } finally {
                yield put(DocumentsReducerAction.setIsLoading(false));
            }
        },
        *fetchDocumentUnitsByPropertyId(action: { payload: string }) {
            try {
                yield put(DocumentsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetDocumentUnitsByPropertyId, action.payload);
                if (response?.status === 200) {
                    DocumentsReducerAction.setUnitsList([]);
                    yield put(DocumentsReducerAction.setUnitsList(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Document Properties list API', error);
            } finally {
                yield put(DocumentsReducerAction.setIsLoading(false));
            }
        },
        *fetchDocumentDetailsById(action: { payload: string }) {
            try {
                yield put(DocumentsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(GetDocumentDetailsById, action.payload);
                if (response?.status === 200) {
                    yield put(DocumentsReducerAction.setSelectedDocument({}));
                    yield put(DocumentsReducerAction.setSelectedDocument(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Document Details API', error);
            } finally {
                yield put(DocumentsReducerAction.setIsLoading(false));
            }
        }
    }
});

export const { actions: DocumentsSagaAction, name: DocumentsSagaName, saga: DocumentsSaga } = saga;
