import axiosServices from 'utils/axios';

export const GetUsersByRole = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post('/user/user-by-roles', data);
        return response;
    } catch (error) {
        return error;
    }
};

export const DownloadFile = async (data: { [key: string]: any }) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/download`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const ForgotPassword = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post('/user/forgot-password', data);
        return response;
    } catch (error) {
        return error;
    }
};

export const ResetPassword = async (data: { values: { [key: string]: any }; token: string }) => {
    try {
        const { values, token } = data;
        const response = await axiosServices.post(`/user/reset-password/${token}`, values);
        return response;
    } catch (error) {
        return error;
    }
};

export const UpdateUserProfile = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.put(`/user/update-user-details/${data?._id}`, data);
        return response;
    } catch (error) {
        return error;
    }
}

export const GetUserDetailsByToken = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post('/user/user-details-by-accessToken', data);
        return response;
    } catch (error) {
        return error;
    }
}

export const UserResetPassword = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post('/user/change-password', data);
        return response;
    } catch (error) {
        return error;
    }
}
