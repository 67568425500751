import { createSliceSaga } from 'store/saga-helper';
import { call, put } from 'redux-saga/effects';
import { SidepanelReducerAction } from './slice';
import { GetGlobalSearchData } from 'services/sidepanel';
import { ConvertToQueryString } from 'utils/common';

const saga = createSliceSaga({
    name: 'side-panel-state',
    caseSagas: {
        *fetchGlobalSearchData(action: { payload: { [key: string]: any } }) {
            try {
                yield put(SidepanelReducerAction.setIsLoading(true));
                const response: { [key: string]: any } = yield call(GetGlobalSearchData, ConvertToQueryString(action.payload));
                if (response.status === 200) {
                    SidepanelReducerAction.setGlobalSearchList([]);
                    yield put(SidepanelReducerAction.setGlobalSearchList(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in global search API', error);
                throw new Error();
            } finally {
                yield put(SidepanelReducerAction.setIsLoading(false));
            }
        }
    }
});

export const { actions: SidePanelSagaAction, name: SidePanelSagaName, saga: SidePanelSaga } = saga;
