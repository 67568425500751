export type HandleFunction = (i: string, s: string) => Promise<void>;

export type ProfileProgress = {
    label: string;
    variant: string;
    value: number;
};

type UserAddressType = {
    street_address: string;
    street_line_1: string;
    street_line_2: string;
    postcode: null | number;
    country: string;
    city: string;
};

export type UserProfile = {
    _id: string;
    first_name: string;
    profile_img: string;
    last_name: string;
    email: string;
    phone: string;
    birth_date: string;
    company_name: string;
    country: null | string;
    user_type: string;
    is_email_verified: boolean;
    __v: number;
    address: UserAddressType;
    welcome_msg: string;
    background_img: string;
};

export enum UserTypeEnum {
    ADMIN = 'ADMIN',
    MANAGER = 'MANAGER',
    AGENT = 'AGENT',
    CLIENT = 'CLIENT',
    AGENCY = 'AGENCY'
}

export type Profile = {
    id: string;
    avatar: string;
    name: string;
    time: string;
};

export type PostImage = {
    img: string;
    featured?: boolean;
    title?: string;
};

export type Likes = {
    like: boolean;
    value: number;
};

export type Group = {
    id: string;
    avatar: string;
    name: string;
};

export type Reply = {
    id: string;
    profile: Profile;
    data: CommentData;
};

export type CommentData = {
    name?: string;
    comment?: string;
    likes?: Likes;
    video?: string;
    replies?: Reply[];
};

export type PostData = {
    id?: string;
    content: string;
    images: PostImage[];
    video?: string;
    likes: Likes;
    comments?: Comment[];
};
export type Comment = {
    id: string;
    profile: Profile;
    data?: CommentData;
};
export type Post = {
    id?: string;
    profile: Profile;
    data: PostData;
};

export type PostDataType = { id: string; data: PostData; profile: Profile };

export interface PostProps {
    commentAdd: (s: string, c: Reply) => Promise<void>;
    handleCommentLikes: HandleFunction;
    editPost?: HandleFunction;
    renderPost?: HandleFunction;
    setPosts?: React.Dispatch<React.SetStateAction<PostDataType[]>>;
    handlePostLikes: (s: string) => Promise<void>;
    handleReplayLikes: (postId: string, commentId: string, replayId: string) => Promise<void>;
    post: PostDataType;
    replyAdd: (postId: string, commentId: string, reply: Reply) => Promise<void>;
}
