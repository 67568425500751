import { Theme } from '@mui/material/styles';

import { UserTick } from 'ui-component/Icons';

import color from 'assets/scss/_themes-vars.module.scss';

export const quillStyleHelper = (theme: Theme) => ({
    '& .quill': {
        bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
        borderRadius: '12px',
        '& .ql-toolbar': {
            bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.100',
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            backgroundColor: color.grey50
        },
        '& .ql-container': {
            borderColor: theme.palette.mode === 'dark' ? `${theme.palette.dark.light + 20} !important` : 'primary.light',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
            backgroundColor: 'white',
            height: 'unset',
            '& .ql-editor': {
                minHeight: 135
            }
        }
    }
});

export const documentTabsOption = [
    {
        label: (
            <span>
                <span className="tab-header">Document Name</span>
                <br />
                <span className="tab-sub-header">Profile Setting</span>
            </span>
        ),
        icon: UserTick
    }
];
