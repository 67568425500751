import axiosServices from 'utils/axios';

export const GetDocumentsByUserId = async ({ id, query }: { id: string; query: string }) => {
    try {
        const response = await axiosServices.get(`/document/user-documents/${id}?${query}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetAllDocuments = async (query?: string) => {
    try {
        const response = await axiosServices.get(`/document/allDocuments?${query}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const DeleteDocumentById = async (id: string) => {
    try {
        const response = await axiosServices.delete(`/document/delete-document/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const AddDocument = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.post('/document/saveDocument', data);
        return response;
    } catch (error) {
        return error;
    }
};

export const UpdateDocumentById = async (data: { [key: string]: any }) => {
    try {
        const response = await axiosServices.put(`/document/update-document-details/${data?._id}`, data);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetDocumentProperties = async () => {
    try {
        const response = await axiosServices.get('/document/property-list');
        return response;
    } catch (error) {
        return error;
    }
};

export const GetDocumentUnitsByPropertyId = async (id: string) => {
    try {
        const response = await axiosServices.get(`/document/unit-list?property_id=${id}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const GetDocumentDetailsById = async (id: string) => {
    try {
        const response = await axiosServices.get(`/document/document-details/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};
