import { Box } from '@mui/system';

import { Theme } from '@mui/material/styles';
import {
    PropertyCardsIcon,
    PropertyListIcon,
    PropertyFilterIcon,
    PropertyPrintIcon,
    PropertyExportIcon,
    CalendarToday,
    TimelineIcon
} from 'ui-component/Icons';

import { TabsProps } from 'types';

// tabs option
export const tabsOption = [
    {
        label: 'Cards',
        icon: <PropertyCardsIcon />
    },
    {
        label: 'List',
        icon: <PropertyListIcon />
    },
    {
        label: 'Calendar',
        icon: <CalendarToday />
    }
    // {
    //     label: 'Map',
    //     icon: <PropertyMapsIcon />
    // }
];

export const tabsOptionWithoutSubheader = [
    {
        label: 'Cards',
        icon: <PropertyCardsIcon />
    },
    {
        label: 'List',
        icon: <PropertyListIcon />
    }
];

export const agentsclientsTabOption = [
    {
        label: 'Timeline',
        icon: <TimelineIcon />
    },
    {
        label: 'Cards',
        icon: <PropertyCardsIcon />
    },
    {
        label: 'Calendar',
        icon: <CalendarToday />
    },
    {
        label: 'List',
        icon: <PropertyListIcon />
    }
    // {
    //     label: 'Map',
    //     icon: <PropertyMapsIcon />
    // }
];

export const agencyClientsTabOption = [
    {
        label: 'Cards',
        icon: <PropertyCardsIcon />
    },
    {
        label: 'List',
        icon: <PropertyListIcon />
    }
];

export const withoutSubHeaderAgencyClientsTabOption = [
    {
        label: 'Cards',
        icon: <PropertyCardsIcon />
    },
    {
        label: 'List',
        icon: <PropertyListIcon />
    }
    // {
    //     label: 'Map',
    //     icon: <PropertyMapsIcon />
    // }
];

// tabs option
export const buttonOption = [
    {
        label: 'Sort by',
        icon: <PropertyFilterIcon />
    },
    {
        label: 'Print',
        icon: <PropertyPrintIcon />
    },
    {
        label: 'Export',
        icon: <PropertyExportIcon />
    }
];

export const MapViewData = [
    {
        userImg: '1M1BGFu-qtMeirQK12w5NwSKDiKrMMzgb',
        userName: 'Azo',
        userSub: 'Blending Stones',
        latitude: 38.704529,
        longitude: -9.42078,
        address: 'Av. Eng. António de Azevedo Coutinho 4-124',
        value: 20
    },
    {
        userImg: '1lY4U3EqLUiVmT45hWzVn9EgAuDZSZYYV',
        userName: 'Santa Isabel',
        userSub: 'Blending Stones',
        latitude: 38.717818,
        longitude: -9.1581781,
        address: 'Av. Eng. António de Azevedo Coutinho 4-124',
        value: 20
    },
    {
        userImg: '1LKxh4hC_VWBUOwcUW5ykzeKXn6qTI0CR',
        userName: 'Trigo',
        userSub: 'Blending Stones',
        latitude: 38.7100279,
        longitude: -9.1292528,
        address: 'Av. Eng. António de Azevedo Coutinho 4-124',
        value: 20
    },
    {
        userImg: '1M1BGFu-qtMeirQK12w5NwSKDiKrMMzgb',
        userName: 'Rossio 93',
        userSub: 'Blending Stones',
        latitude: 38.7142554,
        longitude: -9.1392108,
        address: 'Av. Eng. António de Azevedo Coutinho 4-124',
        value: 20
    }
];

export const updatesTabTimeLine = [
    {
        id: 1,
        title: 'Palacio Santa Clara',
        subTitle: 'PHOTO GALLERY | DECEMBER 2023',
        date: '28/12/2023',
        details:
            'Below is a progress report on the works that are currently being developed in the 3 Buildings of the Palácio de Santa Clara – The Standard Residences. The pace has been constant and consolidated and we are on target to close up the construction phase during the last quarter of 2023.',
        image: '1gljcSK-krcKQiu2wOEGqof0XQVrI5w7W',
        views: 20,
        files: 3
    },
    {
        id: 2,
        title: 'Palacio Santa Clara',
        subTitle: 'UPDATE | JANUARY 2023',
        date: '28/12/2023',
        details:
            'Below is a progress report on the works that are currently being developed in the 3 Buildings of the Palácio de Santa Clara – The Standard Residences. The pace has been constant and consolidated and we are on target to close up the construction phase during the last quarter of 2023.',
        image: '1ISr5IxwMZK5XGyBGWmpbfwldJMCt24Ob',
        views: 20,
        files: 3
    },
    {
        id: 3,
        title: 'Palacio Santa Clara',
        subTitle: 'PHOTO GALLERY | DECEMBER 2023',
        date: '28/12/2023',
        details:
            'Below is a progress report on the works that are currently being developed in the 3 Buildings of the Palácio de Santa Clara – The Standard Residences. The pace has been constant and consolidated and we are on target to close up the construction phase during the last quarter of 2023.',
        image: '1gljcSK-krcKQiu2wOEGqof0XQVrI5w7W',
        views: 20,
        files: 3
    }
];
export const updatesTabCards = [
    {
        name: 'UPDATE | NOVEMBER 2023',
        property: 'Jardim da Glória',
        owner: 'Public',
        image: 'https://stonejourney.pt/wp-content/uploads/2023/08/VMP_opening_67-1-326x216.jpg',
        address: 'All',
        unit: 'All',
        category: 'Development',
        date: 'Fri Mar 21 2023',
        date2: '21/3/2023',
        documents: '2',
        status: 'draft'
    },
    {
        name: 'PHOTO GALLERY | FEB 2023',
        property: 'Palacio Santa Clara',
        owner: 'Public',
        image: 'https://stonejourney.pt/wp-content/uploads/2023/08/VMP_opening_67-1-326x216.jpg',
        address: 'All',
        unit: 'All',
        category: 'Development',
        date: 'Wed Feb 22 2023',
        date2: '21/3/2023',
        documents: '2',
        status: 'draft'
    },
    {
        name: 'UPDATE | FEBRUARY 2023',
        property: 'Rossio 85',
        owner: 'Public',
        image: 'https://stonejourney.pt/wp-content/uploads/2023/08/VMP_opening_67-1-326x216.jpg',
        address: 'All',
        unit: 'All',
        category: 'Development',
        date: 'Mon Jul 12 2023',
        date2: '21/3/2023',
        documents: '2',
        status: 'draft'
    },
    {
        name: 'UPDATE | FEBRUARY 2023',
        property: 'Villa Maria Pia',
        owner: 'Public',
        image: 'https://stonejourney.pt/wp-content/uploads/2023/08/BEATAS_July23_33-326x216.jpg',
        address: 'All',
        unit: 'All',
        category: 'Development',
        date: 'Sat Mar 20 2023',
        date2: '21/3/2023',
        documents: '2',
        status: 'draft'
    },
    {
        name: 'VILLA MARIA PIA | USE PERMIT GRANT',
        property: 'Villa Maria Pia',
        owner: 'Public',
        image: 'https://stonejourney.pt/wp-content/uploads/2023/08/VMP_opening_67-1-326x216.jpg',
        address: 'All',
        unit: 'All',
        category: 'Legal',
        date: 'Fri Mar 21 2023',
        date2: '21/3/2023',
        documents: '2',
        status: 'draft'
    },
    {
        name: 'TAX BENEFITS | AUGUST 2023',
        property: 'Villa Maria Pia',
        owner: 'Public',
        image: 'https://stonejourney.pt/wp-content/uploads/2023/07/trigo_may23_32-326x216.jpg',
        address: 'All',
        unit: 'All',
        category: 'Legal',
        date: 'Wed Feb 22 2023',
        date2: '21/3/2023',
        documents: '0',
        status: 'draft'
    },
    {
        name: 'UPDATE | AUGUST 2023',
        property: 'Rossio 85',
        owner: 'Public',
        image: 'https://stonejourney.pt/wp-content/uploads/2023/05/SC_may23_19-326x216.jpg',
        address: 'All',
        unit: 'All',
        category: 'Updates',
        date: 'Mon Jul 12 2023',
        date2: '21/3/2023',
        documents: '5',
        status: 'draft'
    },
    {
        name: 'UPDATE | FEBRUARY 2023',
        property: 'Villa Maria Pia',
        owner: 'Public',
        image: 'https://stonejourney.pt/wp-content/uploads/2023/08/VMP_opening_67-1-326x216.jpg',
        address: 'All',
        unit: 'All',
        category: 'Development',
        date: 'Sat Mar 20 2023',
        date2: '21/3/2023',
        documents: '2',
        status: 'draft'
    }
];

export const updatesManagerTabCards = [
    {
        image: 'https://stonejourney.pt/wp-content/uploads/2023/08/VMP_opening_67-1-326x216.jpg',
        name: 'UPDATE | NOVEMBER 2023',
        category: 'Financial',
        client: 30,
        other: 5,
        documents: 2,
        views: 2,
        date: '21/3/2023',
        status: 'Published'
    }
];

export function TabPanel({ children, value, index, ...other }: TabsProps) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

export const tabStyle = (theme: Theme) => ({
    mb: 3,
    '& a': {
        minHeight: 'auto',
        minWidth: 10,
        py: 1.5,
        px: 1,
        mr: 2.25,
        color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& .MuiTabs-scroller': {
        height: 'fit-content'
    },
    '& a.Mui-selected': {
        color: theme.palette.primary.main
    },
    '& .MuiTabs-indicator': {
        bottom: 2
    },
    '& a > svg': {
        marginBottom: '0px !important',
        mr: 1.25
    },
    '& .MuiTabs-flexContainer': {
        border: 'none !important'
    }
});
